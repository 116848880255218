import React from 'react';
import './styles.css';
import Navbar from '../components/navbar/navbar';
import { Link } from 'gatsby';

const About = () => {
  return (
    <>
      <Navbar title="About Email Validation Service - Free Spam checker" />
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <article className='article'>
              <h1 className="article-title"><Link to='/about'>About Email Validation Service</Link></h1>
              <p className="text-lead">
                <Link to="/">Email Validation</Link> is a free service that checks your email and gives you a spam score to help get your email through to inboxes, effectively.
              </p>
              <p>
                <Link to="/">Email Validation</Link> checks your email against a number of key factors to make sure that your emails are going through to your customers. Your email is checked against spam IPs lists and your headers are measured to make sure that everything is in order. A score (out of 5) is presented along with a list of headers that email readers parse through to check whether your email is valid should be labeled as spam or not. Simple as that!
              </p>
              <p>
                If you want to learn how to use this service, head out to <Link to="/how-it-works">How it works</Link> and get started!
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;